.nav_bar{
    background-color: white;
    border-bottom: 1px solid gray;
    height: 50px;
}

.nav_bar .container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
}

.logo{
    max-width: 150px;
}