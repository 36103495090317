.modal{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal_block{
    background-color: white;
    border-radius: 30px;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding: 50px;
    position: relative;
}

.modal_block >h3{
    border-bottom: 1px solid gray;
    padding: 15px;
    width: 100%;
    font-weight: 300;
    
}  

.close{
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
}

@media(max-width: 480px){
   .modal_block{
    width: 95%;
    padding: 20px 20px;
}

.modal_block >h3{
    font-size: 14px;
}
}