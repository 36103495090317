
.form_div{
    display: flex;
    justify-content: center;
    margin-top: 50px;
}
.feedBack{
    background-color: white;
    border-radius: 30px;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    padding: 20px 50px;
}

.feedBack >h3{
    border-bottom: 1px solid gray;
    padding: 15px;
    width: 100%;
    /* font-weight: 400; */
    
}

.feedBack div >input{
    border: 1px solid gray;
    padding: 5px;
    height: 30px;
    width: 250px;
    outline: none;
    border-radius: 3px;
}


.feedBack .phone{
    display: flex;
    align-items: center;
}

.feedBack .phone >span{
    border: 1px solid gray;
    color: gray;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 4px;
    background-color: rgba(128, 128, 128, 0.262);
}

.feedBack .phone-inp{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.feedBack  .phone-inp >p{
    color: black;
    font-size: 16px;
    font-weight: 200;
}

.feedBack  .phone >input{
    border: 1px solid gray;
    padding: 5px;
    height: 30px;
    width: 250px;
    outline: none;
    border-left: none;
}

.feedBack div >p.error_formik{
    color: red;
    font-size: 14px;
}

.feedBack_btn{
    background-color: #3E9F8B;
    outline: none;
    border: none;
    color: white;
    border-radius: 3px;
    padding: 6px 20px;
    cursor: pointer;
}

.feedBack_btn:hover{
    background-color: #5dc4af;
}

.place{
    padding-bottom: 10px;
}

.feedBack .radio-inp{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.feedBack .radio-inp label{
    display: flex;
    gap: 10px;
    width: 50px;
}

.delay_labels_div{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.delay_labels_div label{
    display: flex;
    gap: 5px;
}



@media(max-width: 480px){
.phone >input{
    width: 200px;
}

.feedBack{
    padding: 20px 20px;
    width: 95%;
}
}