@import url('https://fonts.googleapis.com/css2?family=Edu+AU+VIC+WA+NT+Hand:wght@400..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    background-color: rgb(235, 233, 233);
    font-family: "Roboto", sans-serif;
    font-style: normal;
  
}

.container{
    max-width: 1440px;
    margin: 0 auto;
}

img{
    width: 100%;
}

ul{
    list-style: none;
}

a{
    text-decoration: none;
}


